<template>
  <v-container id="ourvalues" class="ma-auto" fluid>
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="text-center">
        <span class="text-h4 text-uppercase">{{$t("ourvalues")}}</span>
      </v-col>
      <v-row class="ma-0 pa-0" v-if="company_value_loading || !values.length"> 
        <v-col cols="12" v-for="i in 3" :key="i" class="ma-auto" sm="8" md="4" data-aos="fade-down">
          <v-skeleton-loader class="pa-4"
            type="list-item-avatar, list-item-three-line, card-heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-col cols="12" sm="4" v-for="value in values" data-aos="zoom-in" :key="value.title">
        <v-card class="values-cards elevation-0" outlined>
          <v-card-title>
            <v-icon class="custom-accent--text"
              large
              left
            >
              {{value.icon}}
            </v-icon>
            <span class="text-h6 font-weight-light" v-line-clamp="1">{{$i18n.locale=='en'?value.titleEN:value.titleFR}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="description font-weight-light custom-secondary--text" style="min-height: 6rem !important;">
            <span v-line-clamp="3">{{$i18n.locale=='en'?value.descriptionEN:value.descriptionFR}}</span>
          </v-card-text>
          <v-card-actions>
            <v-btn color="custom-accent" text @click="$navigateToPath(value.route)">
              {{$t("viewmore")}}
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "OurValues",
  data() {
    return {
      company:null,
      values:[],
    };
  },
  components: {},
  async created(){
    await this.retrieveCompanies().then(()=>{
      this.company = this.companies[0];
    });
    await this.retrieveCompanyValues().then(()=>{
      this.values = this.company_values.filter(item=>{return item.company==this.companies[0].id && item.type=='ourvalues'});
    });
  },
  computed: {
    ...mapGetters('company', {
      companies:'getCompanies'
    }),
    ...mapState("company", {
      company_loading: "loading"
    }),
    ...mapGetters('company_value', {
      company_values:'getCompanyValues'
    }),
    ...mapState("company_value", {
      company_value_loading: "loading"
    }),
  },
  watch: {},
  methods: {
    
    ...mapActions("company", ["addCompany","retrieveCompanies"]),
    ...mapActions("company_value", ["addCompanyValue","retrieveCompanyValues"]),
  },
};
</script>
 
